import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import useTranslation from "sholdi-hooks/useTranslation";

import ArrowRight from "sholdi-icons/ArrowRight";
import Text from "sholdi-primitives/atoms/Text";
import Link from "sholdi-primitives/atoms/Link";
import Button from "sholdi-primitives/atoms/Button";

const ListHeader = ({
  title,
  subtitle,
  children,
  href = "#",
  buttonLabel,
  showShowMoreButton,
  titleProps = {},
  className,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={twMerge(
        "w-full mb-10 flex items-center justify-between",
        className,
      )}
      {...props}
    >
      <div className="flex items-start md:items-center flex-col md:flex-row w-fit">
        <div>
          <Text
            variant="h2"
            className="font-bold text-lg sm:text-xl leading-6 mr-2"
            {...titleProps}
          >
            {t(title) ?? title}
          </Text>
          <Text
            variant="h3"
            className="font-normal text-gray-800 mr-2 mt-1 text-xs sm:text-sm"
          >
            {subtitle}
          </Text>
        </div>
        {children}
      </div>
      {showShowMoreButton && (
        <Button
          as={Link}
          href={href}
          variant="primaryTransparent"
          icon={ArrowRight}
          iconProps={{ className: "size-4" }}
          direction="rtl"
          buttonSize="xs"
          className="mr-2 min-w-fit leading-6"
        >
          {buttonLabel || t("viewAll")}
        </Button>
      )}
    </div>
  );
};

ListHeader.displayName = "ListHeader";

ListHeader.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  buttonLabel: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  showShowMoreButton: PropTypes.bool,
  titleProps: PropTypes.shape({}),
  className: PropTypes.string,
};

export default ListHeader;
